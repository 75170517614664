import { useContext, useEffect } from "react"
import { Link } from "react-router-dom"
import { Context } from "../store/store"
import TextBook from "../components/TextBook"
import { TextBookKeys } from "../config"
import Spinner from "../elements/Spinner"
import { getText } from "../strapi"
import Sogehts from "./Sogehts"

function Promosoon() {
	const { globalState } = useContext(Context)

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}, [])

	return (
		<>
			<div className="relative z-10  mt-0 md:-mt-6">
				<picture>
					<source media="(min-width: 640px)" srcSet={process.env.PUBLIC_URL + "/images/hero.png"} />
					<img
						src={process.env.PUBLIC_URL + "/images/header-mobile.png"}
						className="w-full relative z-0 block mb:hidden mt-12 md:mt-0"
						alt=""
					/>
				</picture>
			</div>
			<section className="bg-center bg-cover bg-no-repeat pt-24 relative bg-[#d7f4fc] md:pt-48 -mt-[16%] md:-mt-[11%]">
				<img
					src={process.env.PUBLIC_URL + "/images/pomosoon-badge.png"}
					alt="alt"
					width={150}
					height={150}
					className="w-[150px] md:w-[350px] absolute -top-[5%] md:-top-[12%] md:left-[24%] z-[80]"
				/>
				{globalState.strapi.homepage ? (
					<div className="-mt-24 md:-mt-[12rem]">
						<Sogehts />
					</div>
				) : (
					<div className="text-center py-20">
						<TextBook textKey={TextBookKeys.HOMEPAGE} />
						<Spinner />
					</div>
				)}
			</section>
		</>
	)
}
export default Promosoon
