import axios from "axios"
import { useEffect, useState } from "react"
import Spinner from "../elements/Spinner"
import { getEnvConfig } from "../env"

const Partners = () => {
	const [partnerMarkup, setPartnerMarkup] = useState(null)

	useEffect(() => {
		const env = getEnvConfig()

		axios
			.get(env.apibase + "marketingtext.php?cv=" + Date.now())
			.then(res => {
				console.log(res.data.data)
				if (res.data.data.partnerCompanies) {
					setPartnerMarkup(replaceLinks(res.data.data.partnerCompanies))
				}
			})
			.catch(error => {
				console.log(error)
			})
			.then(() => {})
		// eslint-disable-next-line
	}, [])

	const replaceLinks = text => {
		const regex = /\[\[(https?:\/\/[^[\]]*)\]\[([^[\]]*)\]\]/g
		return text.replace(regex, (match, p1, p2) => {
			return '<a class="underline" href="' + p1 + '">' + p2 + "</a>"
		})
	}

	return (
		<>
			<div className="relative z-10  mt-0 md:-mt-6">
				<picture>
					<source media="(min-width: 640px)" srcSet={process.env.PUBLIC_URL + "/images/hero.png"} />
					<img
						src={process.env.PUBLIC_URL + "/images/header-mobile.png"}
						className="w-full relative z-0 block mb:hidden mt-12 md:mt-0"
						alt=""
					/>
				</picture>
			</div>
			<section className="bg-white   ">
				<div className="container max-w-[1200px] mx-auto px-8  py-32  ">
					<h1 className="h1 text-left pt-24">Partnerunternehmen</h1>
					{partnerMarkup ? (
						<div dangerouslySetInnerHTML={{ __html: partnerMarkup }}></div>
					) : (
						<div className="text-center">
							<Spinner />
						</div>
					)}
				</div>
			</section>
		</>
	)
}

export default Partners
