import { useContext, useState } from "react"
import ReceiptUpload from "./ReceiptUpload"
import { useAutoAnimate } from "@formkit/auto-animate/react"
import { Context } from "../store/store"
import { getText } from "../strapi"
import ElemDialog from "../elements/Dialog"

const Receipt = () => {
	const { globalState, dispatch } = useContext(Context)
	const [showSample, setShowSample] = useState<boolean>(false)
	const [animationRef] = useAutoAnimate<HTMLDivElement>()

	return (
		<>
			<section className="sm:px-4 pt-6 js-mitmachen relative">
				<div className="container text-center">
					<div>
						<h3 className="text-blueLight">
							{getText(globalState.strapi.homepage.fotoSubline, "fotoSubline")}
						</h3>

						<div className="md:px-8 ">
							<div className="">
								<div className="p-1 sm:px-8  order-1  sm:order-1">
									<div className=" md:px-4 pb-4">
										<img
											src={process.env.PUBLIC_URL + "/images/icon-receipt.png"}
											alt=""
											className="inline-block w-[130px] pb-[1%] h-auto "
										/>
										<h3 className="pb-2 md:mt-12 md:mb-6 ">
											{getText(globalState.strapi.homepage.receiptHeadline, "receiptHeadline")}
										</h3>
										<p className="text-black font-Noto font-bold text-[15px] md:text-[17px] pb-6">
											{getText(globalState.strapi.homepage.receiptCopy, "receiptCopy")}
										</p>
										<ReceiptUpload dispatch={dispatch} />
									</div>
								</div>

								<div
									className="text-red underline cursor-pointer"
									onClick={() => {
										setShowSample(true)
										try {
											// eslint-disable-next-line
											let dataLayer = window["dataLayer"] || []
											dataLayer.push({
												event: "receipt",
												"receipt-upload": "show-sample-receipt",
											})
										} catch (e) {}
									}}
								>
									Beispiel ansehen
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<div ref={animationRef} className="text-center">
				{showSample && (
					<ElemDialog>
						<h3 className="">Beispiel-Kassenbon</h3>
						<img
							src={process.env.PUBLIC_URL + "/images/bon-sample.jpg"}
							width="724"
							height="1462"
							alt=""
							className="mx-auto w-[200px] lg:w-[270px] h-auto "
						/>
						<button className="btn" onClick={() => setShowSample(false)}>
							schließen
						</button>
					</ElemDialog>
				)}
			</div>
		</>
	)
}

export default Receipt
