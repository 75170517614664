import React from "react"

function Deko() {
	return (
		<>
			<img
				src={process.env.PUBLIC_URL + "/images/tropfen.png"}
				alt={"not selected"}
				loading="lazy"
				className="absolute left-0 top-0 w-[80%] md:w-[40%] z-0"
			/>
			<img
				src={process.env.PUBLIC_URL + "/images/tropfen.png"}
				alt={"not selected"}
				loading="lazy"
				className="absolute right-0 bottom-0 w-[80%] md:w-[40%] z-0 -scale-y-100 -scale-x-100"
			/>
			<img
				src={process.env.PUBLIC_URL + "/images/herz.png"}
				alt={"not selected"}
				loading="lazy"
				className="hidden md:block absolute top-[10%] right-[10%] w-[88px]"
			/>
			<img
				src={process.env.PUBLIC_URL + "/images/herz.png"}
				alt={"not selected"}
				loading="lazy"
				className="hidden md:block absolute top-[40%] left-[10%] w-[88px]"
			/>
			<img
				src={process.env.PUBLIC_URL + "/images/herz.png"}
				alt={"not selected"}
				loading="lazy"
				className="hidden md:block absolute top-[90%] right-[10%] w-[88px]"
			/>
			<img
				src={process.env.PUBLIC_URL + "/images/cake.png"}
				alt={"not selected"}
				loading="lazy"
				className="hidden md:block absolute top-[20%] left-[10%] w-[207px] -scale-x-100"
			/>
			<img
				src={process.env.PUBLIC_URL + "/images/cake.png"}
				alt={"not selected"}
				loading="lazy"
				className="hidden md:block absolute top-[45%] right-[10%] w-[207px] "
			/>
			<img
				src={process.env.PUBLIC_URL + "/images/cake.png"}
				alt={"not selected"}
				loading="lazy"
				className="hidden md:block absolute top-[70%] left-[10%] w-[207px] -scale-x-100 "
			/>
		</>
	)
}

export default Deko
