"use client"
import { useContext, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import FerreroForm from "./FerreroForm"
import { Context } from "../store/store"
import { validateOrder } from "./order"
import { ERRORTYPES } from "../config"
import Alert from "../elements/Alert"
import Thankyou from "../nav/Thankyou"
import Sogehts from "../nav/Sogehts"
import { getText } from "../strapi"
import Deko from "../components/Deko"
import { parseErrStep2 } from "./formhelper"
import axios from "axios"
import { getEnvConfig } from "../env"

const CashbackForm = () => {
	const { globalState } = useContext(Context)
	const [formError, setFormError] = useState<ERRORTYPES | null | string>(null)
	const [isLoading, setIsLoading] = useState<boolean>(false)

	const [thankyouPageFormData, setThankyouPageFormData] = useState<any>()

	const {
		register,
		handleSubmit,
		setError,
		setValue,
		watch,
		clearErrors,
		formState: { errors },
	} = useForm()

	useEffect(() => {
		setValue("allMarketingConsent", false)
		setValue("emailMarketingConsent", false)
		setValue("dataMatchingConsent", false)
		setValue("personalAdConsent", false)
		setValue("postalDeliveryConsent", false)

		console.log("reset cep")
		// eslint-disable-next-line
	}, [])

	// ---------------------------- AZ

	useEffect(() => {
		const subscription = watch((value, { name }) => {
			clearErrors()

			if (name === "allMarketingConsent" && value.allMarketingConsent) {
				// allMarketingConsent true
				setValue("emailMarketingConsent", true)
				setValue("dataMatchingConsent", true)
				setValue("personalAdConsent", true)
				setValue("postalDeliveryConsent", true)
			} else if (name === "allMarketingConsent" && value.allMarketingConsent === false) {
				setValue("emailMarketingConsent", false)
				setValue("dataMatchingConsent", false)
				setValue("personalAdConsent", false)
				setValue("postalDeliveryConsent", false)
			} else if (
				value.emailMarketingConsent === false &&
				(value.postalDeliveryConsent || value.dataMatchingConsent || value.personalAdConsent)
			) {
				setError("emailMarketingConsent", { type: "custom", message: "this field is also required then" })
				scrollToErrorField()
			} else if (name === "postalDeliveryConsent" && value.postalDeliveryConsent === false) {
				// if deselect of postalDeliveryConsent field, clear error of emailMarketingConsent
				clearErrors("emailMarketingConsent")
			} else if (name === "emailMarketingConsent" && value.emailMarketingConsent) {
				// reset error if field will be checked, in case error was already triggered
				clearErrors("emailMarketingConsent")
			}
		})
		return () => subscription.unsubscribe()
		// eslint-disable-next-line
	}, [watch])

	// ------------------ form handling

	const scrollToErrorField = () => {
		setTimeout(() => {
			let errField = document.getElementsByClassName("js-formerror")
			if (errField.length > 0) {
				const firstErrorField: any = errField[0]
				window.scrollTo({
					top: window.pageYOffset + firstErrorField.getBoundingClientRect().top - 200,
					left: 0,
					behavior: "smooth",
					// top: firstErrorField.offsetTop, left: 0, behavior: 'smooth'
				})
			}
		}, 150)
	}

	function handleFormSubmit(data) {
		setFormError(null)

		if (globalState.receiptReference === undefined) {
			setFormError(ERRORTYPES.UPLOADSMISSINGRECEIPT)
			return
		}

		setIsLoading(true)

		validateOrder(
			{ ...data, receiptReference: globalState.receiptReference },

			(errStatus: ERRORTYPES) => {
				setFormError(errStatus)
				setIsLoading(false)
				scrollToErrorField()
			},
			() => {
				scrollToErrorField()
				setIsLoading(false)
			},
			() => {
				doChSteps(data, globalState)
			},
			setError
		)

		try {
			// eslint-disable-next-line
			let dataLayer = window["dataLayer"] || []
			dataLayer.push({
				event: "customClick",
				clickName: "jetzt-gratis-bestellen",
			})
		} catch (e) {}
	}
	function scrollToCodeArea() {
		const doc: any = document
		const { top } = doc.querySelector(".js-codeeingeben").getBoundingClientRect()

		window.scrollTo({
			top: top + window.scrollY,
			left: 0,
			behavior: "smooth",
		})

		try {
			// eslint-disable-next-line
			let dataLayer = window["dataLayer"] || []
			dataLayer.push({
				event: "raffle",
				rafflestatus: "code",
			})
		} catch (e) {}
	}

	function doChSteps(data, globalState) {
		const env = getEnvConfig()

		setIsLoading(true)

		let formData = new FormData()
		formData.append("file", globalState.receiptFile)
		formData.append("fields", JSON.stringify({ ...data, receiptReference: globalState.receiptReference }))
		axios
			.post(env.apibase + "ch.php", formData)
			.then(res => {
				setIsLoading(false)
				if (
					res.data.participationId === false ||
					res.data.participationEnrichment === false ||
					res.data.participationFinalization === false
				) {
					handleApiErrors(res.data.errors)
					return
				} else if (res.data.errors) {
					handleApiErrors(res.data.errors)
					return
				}

				if (res.data.participationID === false) {
					setFormError("CH: create participation failed. ")
					return
				} else if (res.data.participationEnrichment === false) {
					parseErrStep2(res.data, setError)
					setFormError(res.data.message)
					return
				} else if (res.data.participationFinalization === false) {
					setFormError("CH: receipt data upload failed")
					return
				}

				setThankyouPageFormData(data)
			})
			.catch(error => {
				console.log("CH error", error)

				setFormError("CH: create participation failed. ")
			})

		const handleApiErrors = errors => {
			Object.keys(errors).forEach((key: string) => {
				if (key === "Birthdate") {
					setFormError("Das Mindestalter für die Newsletter Registrierung beträgt 18 Jahre.")
				} else {
					setFormError(errors[key])
				}
			})
		}
	}

	return (
		<>
			{!thankyouPageFormData && (
				<>
					<div className="relative z-10  mt-0 md:-mt-6">
						<picture>
							<source media="(min-width: 640px)" srcSet={process.env.PUBLIC_URL + "/images/hero.png"} />
							<img
								src={process.env.PUBLIC_URL + "/images/header-mobile.png"}
								className="w-full relative z-0 block mb:hidden mt-12 md:mt-0"
								alt=""
							/>
						</picture>
					</div>
					<div className="relative h-0 text-center z-20 -mt-[12%] ">
						<button className="btn relative top-[8%] lg:mt-6" onClick={() => scrollToCodeArea()}>
							{getText(globalState.strapi.homepage.heroButton, "heroButton")}
						</button>
					</div>
				</>
			)}
			{thankyouPageFormData ? (
				<Thankyou
					address={{
						...thankyouPageFormData,
						optin:
							watch("allMarketingConsent") ||
							watch("emailMarketingConsent") ||
							watch("dataMatchingConsent") ||
							watch("personalAdConsent") ||
							watch("postalDeliveryConsent")
								? true
								: false,
					}}
				/>
			) : (
				<>
					<Sogehts page="start" />
					<div
						className="relative -mt-[7.5%] z-10 pt-[7.5%] js-codeeingeben pb-20"
						style={{
							background: "linear-gradient(270deg, #6AC9F4 0%, #AFE4FC 50%, #6AC9F4 100%)",
						}}
					>
						<Deko />

						<div className="relative z-10 max-w-[720px] px-4 mx-auto">
							<FerreroForm
								handleSubmit={handleSubmit}
								handleFormSubmit={handleFormSubmit}
								handleFormSubmitError={scrollToErrorField}
								register={register}
								errors={errors}
								watch={watch}
								setValue={setValue}
								isLoading={isLoading}
								setError={setError}
								clearErrors={clearErrors}
							/>
							{process.env.NODE_ENV === "development" && (
								<button
									className="btn btn-blue"
									onClick={() => setThankyouPageFormData({ salutation: 1 })}
								>
									zur Dankeseite
								</button>
							)}
							{formError && <Alert className={"my-4 mb-40 js-formerror"}>{formError}</Alert>}
						</div>
					</div>
					<img
						src={process.env.PUBLIC_URL + "/images/bottomLine.png"}
						alt="asd"
						className="w-full relative z-20 -scale-x-100 -mt-[7.5%]"
					/>
					<video
						autoPlay={true}
						controls={false}
						className="relative"
						playsInline={true}
						muted={true}
						loop={true}
						// className="w-full block"
					>
						<source
							src={process.env.PUBLIC_URL + "/images/231222_KIPA_TV15_DE_Titel_DE_Mix.mp4"}
							type="video/mp4"
						/>
					</video>
				</>
			)}
		</>
	)
}

export default CashbackForm
