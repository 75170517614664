"use client"

const Sharing = () => {
	const socialChannels = [
		{
			name: "Mail",
			imgSrc: "/images/share-mail.png",
			link:
				"mailto:?subject=" +
				encodeURI("Jetzt kinder Paradiso gratis sichern!") +
				"&body=" +
				encodeURI(
					"Schon probiert? Jetzt kinder Paradiso gratis testen! Aktionspackung kaufen, Kassenbon auf der Aktionswebsite www.kinder.com/de/de/xp/paradiso-gratistesten hochladen und Kaufbetrag per Banküberweisung erstattet bekommen!"
				),
			onClick: () => {
				try {
					// eslint-disable-next-line
					let dataLayer = window["dataLayer"] || []
					dataLayer.push({
						event: "social",
						socialNetwork: "Email",
					})
				} catch (e) {}
			},
		},
		{
			name: "Whatsapp",
			imgSrc: "/images/share-whatsapp.png",
			link:
				"whatsapp://send?text=" +
				encodeURI(
					"Jetzt kinder Paradiso gratis sichern! \nSchon probiert? Jetzt kinder Paradiso gratis testen! Aktionspackung kaufen, Kassenbon auf der Aktionswebsite www.kinder.com/de/de/xp/paradiso-gratistesten hochladen und Kaufbetrag per Banküberweisung erstattet bekommen!"
				),
			onClick: () => {
				try {
					// 			// eslint-disable-next-line
					let dataLayer = window["dataLayer"] || []
					dataLayer.push({
						event: "social",
						socialNetwork: "Whatsapp",
					})
				} catch (e) {}
			},
		},
		// {
		// 	name: "Pinterest",
		// 	imgSrc: "/images/share-pinterest.png",
		// 	link:
		// 		"https://pinterest.com/pin/create/button/?url=https://www.nutella.com/de/de/xp/snackbox/&media=https://www.nutella.com/de/de/xp/snackbox/images/sharing/1200x630.jpg&description=" +
		// 		encodeURI("Deine nutella® Snackbox"),
		// 	onClick: () => {
		// 		try {
		// 			// eslint-disable-next-line
		// 			let dataLayer = window["dataLayer"] || []
		// 			dataLayer.push({
		// 				event: "social",
		// 				socialNetwork: "Pinterest",
		// 			})
		// 		} catch (e) {}
		// 	},
		// },
		{
			name: "Facebook",
			imgSrc: "/images/share-facebook.png",
			link: "https://www.facebook.com/sharer/sharer.php?u=www.kinder.com/de/de/xp/paradiso-gratistesten",
			onClick: () => {
				try {
					// eslint-disable-next-line
					let dataLayer = window["dataLayer"] || []
					dataLayer.push({
						event: "social",
						socialNetwork: "Facebook",
					})
				} catch (e) {}
			},
		},
		{
			name: "Instagram",
			imgSrc: "/images/share-x.png",
			link:
				"http://x.com/share?text=" +
				encodeURI(
					"Schon probiert? Jetzt kinder Paradiso gratis testen! Aktionspackung kaufen, Kassenbon auf der Aktionswebsite hochladen und Kaufbetrag per Banküberweisung erstattet bekommen!"
				),
			onClick: () => {
				try {
					// eslint-disable-next-line
					let dataLayer = window["dataLayer"] || []
					dataLayer.push({
						event: "customClick",
						clickName: "instagram",
					})
				} catch (e) {}
			},
		},
	]

	return (
		<>
			<div className="bg-yellow mt-0">
				<div className="py-24 text-center">
					<h2 className="text-red"> gleich weitersagen</h2>
					<h3 className="text-blueLight">Die Aktion mit Freunden teilen.</h3>
					<ul className="max-w-[269px] grid grid-cols-4 md:grid-cols-3 gap-4 mx-auto pt-12 items-center">
						{socialChannels.map((option, index) => (
							<li key={index} className={option.name === "Whatsapp" ? "block md:hidden" : ""}>
								<a href={option.link} target="_blank" rel="noreferrer" onClick={option.onClick}>
									<img
										src={process.env.PUBLIC_URL + option.imgSrc}
										alt={option.name}
										className={
											" w-[50px] md:w-full " +
											(option.name === "Whatsapp" ? "h-[55px] w-[55px]" : "")
										}
									/>
								</a>
							</li>
						))}
					</ul>
				</div>
				<img
					src={process.env.PUBLIC_URL + "/images/bottomLine.png"}
					alt="asd"
					className="w-full relative z-20 -scale-x-100 top-[1px]"
				/>
			</div>
		</>
	)
}

export default Sharing
