const PrefillForm = ({ setValue }) => {
	return (
		<>
			{process.env.NODE_ENV === "development" && (
				<button
					className="btn btn-blue"
					onClick={() => {
						setValue("accountHolder", "account holder")
						setValue("iban", "DE75512108001245126199")
						setValue("salutation", 1)
						setValue("kaufdatum", "2024-10-15")
						setValue("kaufbetrag", 1)
						setValue("merchant", "a")
						setValue("firstName", "firstName")
						setValue("lastName", "lastName")
						setValue("email", parseInt(Math.floor(Date.now() / 1000).toString()) + "@fake.de")
						// setValue("dateofbirth", "1988-12-15")
					}}
				>
					+++ prefill form +++
				</button>
			)}
		</>
	)
}

export default PrefillForm
