/**
 *
 */
export const scrollToErrorField = () => {
	setTimeout(() => {
		let errField = document.getElementsByClassName("js-formerror")
		if (errField.length > 0) {
			const firstErrorField: any = errField[0]
			window.scrollTo({
				top: window.pageYOffset + firstErrorField.getBoundingClientRect().top - 200,
				left: 0,
				behavior: "smooth",
				// top: firstErrorField.offsetTop, left: 0, behavior: 'smooth'
			})
		}
	}, 50)
}

export function parseErrStep2(data, setError) {
	const ERRORMAPPING = {
		Salutation: "salutation",
		Firstname: "firstName",
		Surname: "lastName",
		Email: "email",
		PurchaseDate: "kaufdatum",
		ProductPrice: "kaufbetrag",
		Store: "merchant",
		AccountHolder: "accountHolder",
		Iban: "iban",
		Bic: "bic",
		ReceiptId: "recieptId",
		Birthdate: "dateofbirth",
	}

	Object.keys(data.errors).forEach(element => {
		setError(ERRORMAPPING[element], { type: "custom", message: data.errors[element].join(" ") })
	})
}
