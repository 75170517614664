import clsx from "clsx"

interface ElemSelectProps {
	children?: any
	hasError?: any
	errMsg?: any
	leftCount?: any
	label?: any
	required?: any
	value?: any
	labelOnTop?: boolean
}

const ElemSelect: React.FC<ElemSelectProps> = ({
	label,
	value,
	required,
	hasError,
	errMsg,
	leftCount,
	labelOnTop,
	children,
}) => {
	return (
		<label className="relative block">
			{children}

			<span
				className={clsx(
					" transition absolute left-0 top-0 text-black/70   px-4 h-[50px] leading-[50px] whitespace-nowrap pointer-events-none",
					"-translate-y-3 text-[12px] "
				)}
			>
				{label}
				{required && "*"}
			</span>

			{leftCount && (
				<div className="absolute top-6 right-3 text-gray">
					{value.length}/{leftCount}
				</div>
			)}

			{hasError && <div className="formErrorMsg">{hasError.message || errMsg}</div>}
		</label>
	)
}

export default ElemSelect
