import { useContext, useEffect } from "react"
import StrapiContentPages from "../components/StrapiContentPages"
import TextBook from "../components/TextBook"
import { TextBookKeys } from "../config"
import Spinner from "../elements/Spinner"
import { Context } from "../store/store"
import { Link } from "react-router-dom"

const Contakt = () => {
	const { globalState } = useContext(Context)
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}, [])

	return (
		<>
			<div className="relative z-10  mt-0 md:-mt-6">
				<picture>
					<source media="(min-width: 640px)" srcSet={process.env.PUBLIC_URL + "/images/hero.png"} />
					<img
						src={process.env.PUBLIC_URL + "/images/header-mobile.png"}
						className="w-full relative z-0 block mb:hidden mt-12 md:mt-0"
						alt=""
					/>
				</picture>
			</div>
			{globalState.strapi.contact ? (
				<div className="text-center pb-20">
					<StrapiContentPages textBook={globalState.strapi.contact} />
				</div>
			) : (
				<div className="text-center pb-20">
					<Spinner />
					<TextBook textKey={TextBookKeys.CONTACT} />
				</div>
			)}
			<div className="grid grid-cols-1 items-center">
				<Link to="/" className="btn inline-block mx-auto sm:w-auto">
					zurück zur Startseite
				</Link>
			</div>{" "}
		</>
	)
}

export default Contakt
