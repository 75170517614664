import axios from "axios"

const domains = {
	"www.kinder.com": {
		apibase: "/de/de/xp/paradiso-gratistesten/api/",
	},
	"www-paradiso-gratistesten-de-2024.ipaasferrero.com": {
		apibase: "/de/de/xp/paradiso-gratistesten/api/",
	},
	localhost: {
		apibase: "/inte/",
	},
}
export const getEnvConfig = () => {
	return (
		domains[window.location.host] || {
			apibase: "/de/de/xp/paradiso-gratistesten/api/inte/",
		}
	)
}

export const checkOrderLimit = navigate => {
	const env = getEnvConfig()
	axios.get(env.apibase + "checkLimit.php?cv=" + Date.now()).then(res => {
		if (res.data.limitReached) {
			navigate("/promoover")
		}
	})
}

export const containsOnlyAllowedChars = name => {
	// eslint-disable-next-line

	const match = name.match(/[A-Za-zÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÔÕÖØÙÚÛÜÝÞßàáâäåçèéêëìíîïðñòóôõöøùúûüýþÿıOEoeŒœŠš&\\\-., +]/g)
	if (match && match.join("") === name) {
		return true
	}
	return false
}
