import React, { useContext, useEffect } from "react"
import TagManager from "react-gtm-module"
import TextBook from "../components/TextBook"
import { TextBookKeys } from "../config"
import AccordionItem from "../elements/AccordionItem"
import Spinner from "../elements/Spinner"
import { Context } from "../store/store"
import { gatherChildren, getText } from "../strapi"
import { Link } from "react-router-dom"

const FAQ: React.FC = () => {
	const { globalState } = useContext(Context)

	// GTM

	const tagManagerArgs = {
		dataLayer: {
			page: "faq",
		},
		dataLayerName: "dataLayer",
	}
	TagManager.dataLayer(tagManagerArgs)

	// SEO Meta Tags

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}, [])

	let counter: number = 1

	return (
		<>
			<div className="relative z-10  mt-0 md:-mt-6">
				<picture>
					<source media="(min-width: 640px)" srcSet={process.env.PUBLIC_URL + "/images/hero.png"} />
					<img
						src={process.env.PUBLIC_URL + "/images/header-mobile.png"}
						className="w-full relative z-0 block mb:hidden mt-12 md:mt-0"
						alt=""
					/>
				</picture>
			</div>
			<h2>Häufig gestellte Fragen</h2>
			{globalState.strapi.faq ? (
				<>
					<section className="bg-white pageContent">
						<div className="container max-w-[1200px] mx-auto md:px-8 px-2  py-20 sm:py-32  ">
							<h3>{getText(globalState.strapi.faq.headline1, "headline1")}</h3>

							{globalState.strapi.faq.accordion1.AkkordionItems.map((item, key) => (
								<>
									<AccordionItem headline={getText(item.headline, "headline")} num={counter++}>
										{item.content ? gatherChildren(item.content) : "[content]"}
									</AccordionItem>
									<div className="h-0 w-full  border-black"></div>
								</>
							))}
							<h3 className="mt-12 md:mt-24 mb-6">
								{getText(globalState.strapi.faq.headline2, "headline2")}
							</h3>

							{globalState.strapi.faq.accordion2.AkkordionItems.map((item, key) => (
								<>
									<AccordionItem headline={getText(item.headline, "headline")} num={counter++}>
										{item.content ? gatherChildren(item.content) : "[content]"}
									</AccordionItem>
									<div className="h-0 w-full  border-black"></div>
								</>
							))}
							<h3 className="mt-12 md:mt-24 mb-6">
								{getText(globalState.strapi.faq.headline3, "headline3")}
							</h3>

							{globalState.strapi.faq.accordion3.AkkordionItems.map((item, key) => (
								<>
									<AccordionItem headline={getText(item.headline, "headline")} num={counter++}>
										{item.content ? gatherChildren(item.content) : "[content]"}
									</AccordionItem>
									<div className="h-0 w-full  border-black"></div>
								</>
							))}
							<h3 className="mt-12 md:mt-24 mb-6">
								{getText(globalState.strapi.faq.headline4, "headline4")}
							</h3>

							{globalState.strapi.faq.accordion4.AkkordionItems.map((item, key) => (
								<>
									<AccordionItem headline={getText(item.headline, "headline")} num={counter++}>
										{item.content ? gatherChildren(item.content) : "[content]"}
									</AccordionItem>
									<div className="h-0 w-full  border-black"></div>
								</>
							))}
						</div>
					</section>
				</>
			) : (
				<div className="text-center py-20">
					<Spinner />
					<TextBook textKey={TextBookKeys.FAQ} />
				</div>
			)}
			<div className="grid grid-cols-1 items-center">
				<Link to="/" className="btn inline-block mx-auto sm:w-auto">
					zurück zur Startseite
				</Link>
			</div>{" "}
		</>
	)
}
export default FAQ
