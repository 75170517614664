import clsx from "clsx"
import { Link } from "react-router-dom"
import { formRegEx, MAX_PRODUCT_PRICE } from "../config"
import ElemInput from "../elements/Input"
import ElemCheckbox from "../elements/Checkbox"
import ElemSelect from "../elements/Select"

export function FieldAccountHolder({ register, errors, watch }) {
	return (
		<ElemInput
			label="Kontoinhaber"
			errMsg={"Bitte Kontoinhaber angeben."}
			required={true}
			value={watch("accountHolder", false)}
			hasError={errors.accountHolder}
		>
			<input
				{...register("accountHolder", { required: true, minLength: 1 })}
				type="text"
				className={clsx("peer inputElem", errors.accountHolder ? "inputElemErr js-formerror" : "bg-white")}
			/>
		</ElemInput>
	)
}
export function FieldAccountIban({ register, errors, watch }) {
	return (
		<ElemInput
			label="IBAN"
			errMsg={"Bitte IBAN angeben."}
			required={true}
			value={watch("iban", false)}
			hasError={errors.iban}
		>
			<input
				{...register("iban", { required: true, minLength: 16, maxLength: 31 })}
				type="text"
				className={clsx("peer inputElem", errors.iban ? "inputElemErr js-formerror" : "bg-white")}
			/>
		</ElemInput>
	)
}
export function FieldAccountBic({ register, errors, watch }) {
	return (
		<ElemInput
			label="BIC"
			errMsg={"Bitte BIC angeben."}
			required={true}
			value={watch("bic", false)}
			hasError={errors.bic}
		>
			<input
				{...register("bic", {
					maxLength: 11,
				})}
				type="text"
				className={clsx("peer inputElem", errors.bic ? "inputElemErr js-formerror" : "bg-white")}
			/>
		</ElemInput>
	)
}
export function FieldSalutation({ register, errors, watch }) {
	return (
		<ElemSelect
			label={"Anrede"}
			errMsg={"Bitte Anrede eingeben."}
			value={watch("salutation", false)}
			hasError={errors.salutation}
		>
			<select
				{...register("salutation", { required: true })}
				className={clsx(
					"peer inputElem selectElem appearance-none ",
					errors.merchant ? "inputElemErr js-formerror" : "bg-white"
				)}
			>
				<option value={4}>{"nicht angegeben"}</option>
				<option value={1}>{"Herr"}</option>
				<option value={2}>{"Frau"}</option>
				<option value={3}>{"neutrale Anrede"}</option>
			</select>
		</ElemSelect>
	)
}

export function FieldKaufdatum({ register, errors, watch }) {
	return (
		<ElemInput
			label={"Kaufdatum*"}
			errMsg={"Bitte Kaufdatum angeben."}
			value={watch("kaufdatum", false)}
			labelOnTop={true}
			hasError={errors.kaufdatum}
		>
			<input
				{...register("kaufdatum", { minLength: 1, required: true })}
				placeholder="TT.MM.YYYY"
				minLength={1}
				min="2024-09-02"
				type="date"
				className={clsx("peer inputElem ", errors.kaufdatum ? "inputElemErr js-formerror" : "bg-white")}
			/>
		</ElemInput>
	)
}
export function FieldKaufbetrag({ register, errors, watch }) {
	return (
		<ElemInput
			label={"Kaufbetrag*"}
			hasError={errors.kaufbetrag}
			errMsg={"Bitte Kaufbetrag angeben."}
			value={watch("kaufbetrag", false)}
		>
			<input
				{...register("kaufbetrag", {
					required: true,
					max: 3,
				})}
				type={"number"}
				step={"0.01"}
				min={"0.1"}
				max={MAX_PRODUCT_PRICE}
				className={clsx(
					"peer inputElem appearance-none ",
					errors.kaufbetrag ? "inputElemErr js-formerror" : "bg-white"
				)}
			/>
		</ElemInput>
	)
}
export function FieldKaufstätte({ register, errors, watch }) {
	return (
		<ElemSelect
			label={"Einkaufsstätte*"}
			hasError={errors.merchant}
			errMsg={"Bitte Einkaufsstätte angeben."}
			value={watch("merchant", false)}
		>
			<select
				{...register("merchant", {
					minLength: 1,
					required: true,
				})}
				className={clsx(
					"peer inputElem selectElem appearance-none ",
					errors.merchant ? "inputElemErr js-formerror" : "bg-white"
				)}
			>
				<option value="">bitte wählen</option>

				<option value="a">Aldi Nord</option>
				<option value="b">Aldi Süd</option>
				<option value="c">Edeka</option>
				<option value="d">Globus</option>
				<option value="e">Hit</option>
				<option value="f">Kaufland</option>
				<option value="g">Lidl</option>
				<option value="h">Netto Marken-Discount</option>
				<option value="i">Netto Supermarkt</option>
				<option value="j">Penny</option>
				<option value="k">Real</option>
				<option value="l">Rewe</option>
				<option value="m">Tegut</option>
				<option value="n">Sonstige</option>
			</select>
		</ElemSelect>
	)
}

export function FieldFirstname({ register, errors, watch }) {
	return (
		<ElemInput
			label="Vorname"
			hasError={errors.firstName}
			errMsg={"Bitte Vornamen angeben."}
			required={true}
			value={watch("firstName", false)}
		>
			<input
				{...register("firstName", { required: true, minLength: 1, pattern: formRegEx.alpha })}
				type="text"
				className={clsx("peer inputElem", errors.firstName ? "inputElemErr js-formerror" : " bg-white")}
			/>
		</ElemInput>
	)
}

export function FieldLastname({ register, errors, watch }) {
	return (
		<ElemInput
			label="Nachname"
			hasError={errors.lastName}
			errMsg={"Bitte Nachnamen angeben."}
			required={true}
			value={watch("lastName", false)}
		>
			<input
				{...register("lastName", {
					minLength: 1,
					pattern: formRegEx.alpha,
					required: true,
				})}
				type="text"
				className={clsx("peer inputElem", errors.lastName ? "inputElemErr js-formerror" : " bg-white")}
			/>
		</ElemInput>
	)
}

export function FieldEmail({ register, errors, watch, isRequired = false }) {
	return (
		<ElemInput
			label={"E-Mail-Adresse" + (isRequired ? "*" : "")}
			hasError={errors.email}
			errMsg={"Bitte E-Mail-Adresse eingeben."}
			// required={true}
			value={watch("email", false)}
		>
			<input
				{...register("email", {
					minLength: 1,
					pattern: formRegEx.email,
					required: isRequired,
				})}
				type="email"
				className={clsx("peer inputElem", errors.email ? "inputElemErr js-formerror" : " bg-white")}
			/>
		</ElemInput>
	)
}

export function FieldTerms({ register, errors }) {
	return (
		<ElemCheckbox
			className="mb-8"
			hasError={errors.terms}
			errMsg={"Bitte Teilnahmebedingungen bestätigen."}
			label={
				<div className="p-link">
					Indem die Schaltfläche "Jetzt teilnehmen" angeklickt wird, wird den{" "}
					<Link rel="noopener noreferrer" to="/teilnahmebedingungen">
						Teilnahmebedingungen
					</Link>{" "}
					der Ferrero Deutschland GmbH zugestimmt und das Mindestalter von 18 Jahren bestätigt. <br />
					Die Datenschutzbestimmungen sind{" "}
					<Link rel="noopener noreferrer" to="/datenschutz" className="inline-block">
						hier
					</Link>{" "}
					zu finden.*
				</div>
			}
		>
			<input
				{...register("terms", { required: true })}
				type="checkbox"
				className="w-1 h-1 hidden absolute peer"
			/>
		</ElemCheckbox>
	)
}

export function FieldBirthday({ register, errors, watch }) {
	return (
		<ElemInput
			label="Geburtsdatum *"
			hasError={errors.dateofbirth}
			errMsg={"Das Mindestalter für die Newsletter Registrierung beträgt 18 Jahre."}
			value={watch("dateofbirth", false)}
			labelOnTop={true}
		>
			<input
				{...register("dateofbirth", {
					required: true,
					minLength: 1,
					maxLength: 10,
				})}
				type="date"
				className={clsx("peer inputElem", errors.dateofbirth ? "inputElemErr js-formerror" : " bg-white")}
			/>
		</ElemInput>
	)
}
