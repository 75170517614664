import axios from "axios"
import React, { useContext, useEffect } from "react"
import { getEnvConfig } from "../env"
import { Context } from "../store/store"

interface TextBookProps {
	textKey: string
}

const TextBook: React.FC<TextBookProps> = ({ textKey }) => {
	const { globalState, dispatch } = useContext(Context)

	useEffect(() => {
		const env = getEnvConfig()

		// load textbook for homepage
		if (!globalState.strapi[textKey]) {
			axios
				.post(env.apibase + "textbook.php?cv=" + Date.now(), { key: textKey })
				.then(res => {
					if (res.data.data.attributes) {
						dispatch({ type: "SET_STATE_STRAPI", key: textKey, value: res.data.data.attributes })
					}
				})
				.catch(res => {
					// Fallback, if Strapi is down
					dispatch({ type: "SET_STATE_STRAPI", key: textKey, value: {} })
					console.log(res)
				})
		}
		// eslint-disable-next-line
	}, [])

	return <></>
}

export default TextBook
