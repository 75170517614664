import axios from "axios"
import { getEnvConfig } from "../env"
import { ERRORTYPES } from "../config"
import { scrollToErrorField } from "./formhelper"

const env = getEnvConfig()

/**
 *
 * @param address
 * @param cep
 * @param orderItems
 * @param updateFormState Callback function to update the form state according to order result
 * @param scrollToErrorField
 */
export const validateOrder = (address, updateFormState, scrollToErrorField, successCallback, setError) => {
	const isOneOfCepCheckboxesActive =
		address.allMarketingConsent ||
		address.dataMatchingConsent ||
		address.personalAdConsent ||
		address.postalDeliveryConsent ||
		address.emailMarketingConsent

	const data = {
		...address,
		dateofbirth: isOneOfCepCheckboxesActive ? address.dateofbirth : "", // clear dateofbirth if not mandatory
	}

	axios
		.post(env.apibase + "validateOrder.php?cv=" + Date.now(), data)
		.then(res => {
			if (res.data.code === 400) {
				parseOrderErrors(res.data.errors.children, updateFormState, setError)
			} else {
				successCallback()
			}

		})
		.catch(error => {

			if (error.response && error.response.status >= 400 && error.response.status < 500) {
				// for 40X cases
				try {
					if (
						error.response.data.errors.errors[0] ===
						"Wir k\u00f6nnen die Bestellung leider nicht aufnehmen. Bitte an unser Service Center werden"
					) {
						updateFormState(ERRORTYPES.DOUBBLED)
					}
				} catch (_error) { }
				parseOrderErrors(error.response.data.errors.children, updateFormState, setError)
			} else {
				updateFormState(ERRORTYPES.SERVER)
				scrollToErrorField()
			}
		})
}

/**
 *
 * @param errors
 * @param updateFormState
 * @param scrollToErrorField
 */
const parseOrderErrors = (errors, updateFormState, setError) => {
	if (errors) {
		const ERRORMAPPING = {
			salutation: "salutation",
			firstName: "firstName",
			lastName: "lastName",
			email: "email",
			purchaseDate: "kaufdatum",
			price: "kaufbetrag",
			store: "merchant",
			accountHolder: "accountHolder",
			iban: "iban",
			bic: "bic",
			receiptDataEntries: "recieptId",
		}

		Object.keys(errors).forEach(element => {
			if (errors[element].errors) {
				setError(ERRORMAPPING[element], { type: "custom", message: errors[element].errors.join(" ") })
				scrollToErrorField()
			}
		})

		updateFormState("Bitte die markierten Formularfelder korrigieren.")
	}
}
