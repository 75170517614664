import axios from "axios"
import { useContext, useEffect, useState } from "react"
import TextBook from "../components/TextBook"
import { TextBookKeys } from "../config"
import Spinner from "../elements/Spinner"
import { getEnvConfig } from "../env"
import { Context } from "../store/store"
import { gatherChildren, getText } from "../strapi"

function Thankyou({ address }) {
	const { globalState } = useContext(Context)

	const [sonderVerlosungText, setSonderVerlosungText] = useState<any>(null)
	const [sonderverlosungURL, setSonderverlosungURL] = useState<string | null>(null)

	useEffect(() => {
		loadSonderVerlosungURL()

		// eslint-disable-next-line
	}, [])

	function loadSonderVerlosungURL() {
		const env = getEnvConfig()
		window.scrollTo({
			top: 0,
			left: 0,
		})

		const SALUTATIONS = {
			"1": "Herr",
			"2": "Frau",
			"3": "Neutral",
			"4": null,
		}

		const data = {
			...address,
			salutation: SALUTATIONS[address.salutation.toString()] || null,
		}

		axios
			.post(env.apibase + "sonderverlosung.php?cv=" + Date.now(), data)
			.then(res => {
				setSonderverlosungURL(res.data)
			})
			.catch(error => {
				console.error(error)
			})

		axios
			.get("https://gewinnspiel.mein-ferrero.de/loyalty-gewinnspiel/api-info")
			.then(res => {
				setSonderVerlosungText(res.data)
			})
			.catch(error => {
				console.error(error)
			})
	}

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}, [])

	return (
		<>
			<div className="relative bg-[#d7f4fc] z-0 pt-0  pb-7 ">
				{globalState.strapi.homepage ? (
					<div>
						<img
							src={process.env.PUBLIC_URL + "/images/tropfen.png"}
							alt={"not selected"}
							loading="lazy"
							className="absolute left-0 top-0 w-[40%] max-w-[450px] z-0"
						/>

						<div className="relative z-10 ">
							<div className=" mx-auto px-4 max-w-[1050px] text-center pt-20 sm:pt-32 ">
								<h2 className="text-[27px] md:text-[74px]">
									{getText(globalState.strapi.homepage.dankeHeadline, "dankeHeadline")}
								</h2>

								<p className="mt-6 md:mt-12 leading-[17px] font-bold max-w-[90%] md:max-w-[70%] mx-auto">
									{gatherChildren(globalState.strapi.homepage.dankeCopy)}
								</p>
							</div>
						</div>
					</div>
				) : (
					<div className="text-center py-20">
						<Spinner />
						<TextBook textKey={TextBookKeys.HOMEPAGE} />
					</div>
				)}
			</div>

			<img
				src={process.env.PUBLIC_URL + "/images/bottomLine.png"}
				alt="asd"
				className="w-full relative z-20 -scale-x-100 -scale-y-100  -top-[1px]"
				style={{
					filter: "brightness(0) saturate(100%) invert(95%) sepia(3%) saturate(1516%) hue-rotate(151deg) brightness(99%) contrast(99%)",
				}}
			/>

			<div
				className="relative -mt-[7.5%] z-10 pt-[7.5%] js-codeeingeben pb-20"
				style={{
					background: "linear-gradient(270deg, #6AC9F4 0%, #AFE4FC 50%, #6AC9F4 100%)",
				}}
			>
				{sonderVerlosungText && (
					<div className="grid grid-cols-1 sm:grid-cols-2 gap-4  mx-auto px-4 max-w-[1050px]">
						<div>
							{sonderVerlosungText.images && (
								<picture className="relative z-10">
									<source media="(min-width: 640px)" srcSet={sonderVerlosungText.images.mobile} />
									<img
										src={sonderVerlosungText.images.desktop}
										className="max-w-[230px] sm:max-w-none w-full relative z-0 mx-auto -my-6 "
										alt=""
									/>
								</picture>
							)}
						</div>
						<div>
							<h3
								className="pb-6"
								dangerouslySetInnerHTML={{ __html: sonderVerlosungText.text.formatted.title }}
							/>
							<p
								className="pb-6 p-link"
								dangerouslySetInnerHTML={{ __html: sonderVerlosungText.text.formatted.copy }}
							/>

							<a
								href={sonderverlosungURL || ""}
								target="_blank"
								className="btn btn-blue   inline-block w-full sm:w-auto mb-12"
								onClick={() => {
									try {
										// eslint-disable-next-line
										let dataLayer = window["dataLayer"] || []
										dataLayer.push({ event: "customClick", clickName: "jetzt-anmelden" })
									} catch (e) {}
								}}
							>
								<button>{sonderverlosungURL ? "jetzt teilnehmen " : <Spinner />}</button>
							</a>
						</div>
					</div>
				)}
			</div>
		</>
	)
}

export default Thankyou
