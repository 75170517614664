import axios from "axios"
import { useEffect } from "react"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import Footer from "./components/Footer"
import { getEnvConfig } from "./env"
import Contact from "./nav/Contact"
import Error from "./nav/Error"
import FAQ from "./nav/FAQ"
import Home from "./nav/Home"
import Partners from "./nav/Partners"
import Privacy from "./nav/Privacy"
import Promoover from "./nav/Promoover"
import Promosoon from "./nav/Promosoon"
import Terms from "./nav/Terms"
import Store from "./store/store"

const App = () => {
	const env = getEnvConfig()

	const keepSessionAlife = () => {
		axios.post(env.apibase + "keepalive.php?cv=" + Date.now())
		setTimeout(keepSessionAlife, 30000)
	}

	useEffect(() => {
		window.location.host !== "localhost:3000" && keepSessionAlife()

		// eslint-disable-next-line
	}, [])

	return (
		<BrowserRouter basename={`${process.env.PUBLIC_URL}`}>
			<Store>
				<main className="">
					<Routes>
						<Route path="/" element={<Home />} />
						<Route path="/promosoon" element={<Promosoon />} />
						<Route path="/promoover" element={<Promoover />} />

						<Route path="/faq" element={<FAQ />} />
						<Route path="/datenschutz" element={<Privacy />} />
						<Route path="/teilnahmebedingungen" element={<Terms />} />
						<Route path="/kontakt" element={<Contact />} />
						<Route path="/partner" element={<Partners />} />

						<Route path="/:err" element={<Error />} />
					</Routes>
				</main>
				<Footer />
			</Store>
		</BrowserRouter>
	)
}

export default App
