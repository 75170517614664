import React from "react"
import ElemSelect from "../elements/Select"

function MafoQuestions({ register, errors, watch }) {
	return (
		<div className="max-w-[600px] mx-auto pt-4 mb-4">
			<h3 className="pb-4 mt-6 md:pb-4">Zum Schluss haben wir noch zwei Fragen</h3>

			<div className="mb-6 grid grid-cols-1 gap-10">
				<div>
					<ElemSelect
						label={"Kinder Paradiso ist für mich..."}
						hasError={errors.mafo1}
						errMsg={"Bitte Anrede eingeben."}
						value={watch("mafo1", false)}
					>
						<select {...register("mafo1")} className={"inputElem selectElem w-full text-[22px]"}>
							<option value="">{"Bitte Antwort auswählen"}</option>
							<option value="a">ein regelmäßiger Begleiter</option>
							<option value="b">eine Neuentdeckung</option>
							<option value="c">eine Wiederentdeckung</option>
						</select>
					</ElemSelect>
				</div>
				<div>
					<ElemSelect
						label={"Kinder Paradiso genieße ich am liebsten..."}
						hasError={errors.mafo2}
						errMsg={"Bitte Anrede eingeben."}
						value={watch("mafo2", false)}
					>
						<select {...register("mafo2")} className={"inputElem selectElem w-full text-[22px]"}>
							<option value="">{"Bitte Antwort auswählen"}</option>
							<option value="a">zum Frühstück</option>
							<option value="b">als Dessert</option>
							<option value="c">einfach so zwischendurch</option>
							<option value="d">zusammen mit Kaffee am Nachmittag</option>
							<option value="e">als kleinen Snack am Abend</option>
						</select>
					</ElemSelect>
				</div>
			</div>
		</div>
	)
}

export default MafoQuestions
