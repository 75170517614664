const Reducer = (state, action) => {
	switch (action.type) {
		case "SET_RECEIPT_REFERENCE":
			return {
				...state,
				receiptReference: action.receiptReference,
				receiptFileName: action.receiptFileName,
				receiptFile: action.receiptFile,
			}
		case "SET_STATE_STRAPI":
			return {
				...state,
				strapi: {
					...state.strapi,
					[action.key]: action.value,
				},
			}
		default:
			return state
	}
}

export default Reducer
