import React from "react"
import { useAutoAnimate } from "@formkit/auto-animate/react"

interface AutoAnimateProps {
	className?: string
	children: any
}
const AutoAnimate: React.FC<AutoAnimateProps> = ({ className, children }) => {
	const [animationParent] = useAutoAnimate()
	return (
		<div ref={animationParent} className={className}>
			{children}
		</div>
	)
}

export default AutoAnimate
