import { Link } from "react-router-dom"

const Footer = () => {
	return (
		<footer className=" py-12">
			<ul className="text-center ">
				<li className="inline-block px-6 pb-4">
					<Link
						to="/faq"
						className="leading-[30px] text-[30px] font-Chronic text-blueLight"
						onClick={() => {
							try {
								// eslint-disable-next-line
								let dataLayer = window["dataLayer"] || []
								dataLayer.push({ event: "customClick", clickName: "/faq (subpage)" })
							} catch (e) {}
						}}
					>
						FAQs
					</Link>
				</li>
				<li className="inline-block px-6 pb-4">
					<Link
						to="/teilnahmebedingungen"
						className="leading-[30px] text-[30px] font-Chronic text-blueLight"
						onClick={() => {
							try {
								// eslint-disable-next-line
								let dataLayer = window["dataLayer"] || []
								dataLayer.push({
									event: "customClick",
									clickName: "/teilnahmebedingungen (subpage)",
								})
							} catch (e) {}
						}}
					>
						Teilnahmebedingungen
					</Link>
				</li>
				<li className="inline-block px-6 pb-4">
					<Link
						to="/kontakt"
						className="leading-[30px] text-[30px] font-Chronic text-blueLight"
						onClick={() => {
							try {
								// eslint-disable-next-line
								let dataLayer = window["dataLayer"] || []
								dataLayer.push({ event: "customClick", clickName: "/kontakt (subpage)" })
							} catch (e) {}
						}}
					>
						Kontakt
					</Link>
				</li>
				<li className="inline-block px-6 pb-4">
					<Link
						to="/datenschutz"
						className="leading-[30px] text-[30px] font-Chronic text-blueLight"
						onClick={() => {
							try {
								// eslint-disable-next-line
								let dataLayer = window["dataLayer"] || []
								dataLayer.push({ event: "customClick", clickName: "/datenschutz (subpage)" })
							} catch (e) {}
						}}
					>
						Datenschutz
					</Link>
				</li>
			</ul>
		</footer>
	)
}

export default Footer
