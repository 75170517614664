import { useContext, useEffect } from "react"
import StrapiContentPages from "../components/StrapiContentPages"
import TextBook from "../components/TextBook"
import { TextBookKeys } from "../config"
import Spinner from "../elements/Spinner"
import { Context } from "../store/store"
import { Link } from "react-router-dom"

const Privacy = () => {
	const { globalState } = useContext(Context)

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
		})
	}, [])

	// const [privacyMarkup, setPrivacyMarkup] = useState(null)

	// useEffect(() => {
	// 	const env = getEnvConfig()

	// 	axios
	// 		.post(env!.apibase + "legalinfo.php?cv=" + Date.now())
	// 		.then(res => {
	// 			setPrivacyMarkup(res.data.toString())
	// 		})
	// 		.catch(error => {
	// 			console.log(error)
	// 		})

	// 	window.scrollTo({
	// 		top: 0,
	// 		left: 0,
	// 		behavior: "smooth",
	// 	})
	// }, [])

	return (
		<div>
			<div className="relative z-10  mt-0 md:-mt-6">
				<picture>
					<source media="(min-width: 640px)" srcSet={process.env.PUBLIC_URL + "/images/hero.png"} />
					<img
						src={process.env.PUBLIC_URL + "/images/header-mobile.png"}
						className="w-full relative z-0 block mb:hidden mt-12 md:mt-0"
						alt=""
					/>
				</picture>
			</div>
			{globalState.strapi.extendeprivacy ? (
				<StrapiContentPages textBook={globalState.strapi.extendeprivacy} />
			) : (
				<div className="text-center py-20">
					<Spinner />
					<TextBook textKey={TextBookKeys.EXTENDED_PRIVACY} />
				</div>
			)}
			{/*

			<h1 className="h2  pb-8">
				Erweiterte Datenschutzerklärung <br />
				"Dein Gesicht auf kinder Schokolade"
			</h1>

			 {privacyMarkup ? (
				<div dangerouslySetInnerHTML={{ __html: privacyMarkup }} className="content"></div>
			) : (
				<div className="text-center">
					<Spinner />
				</div>
			)} 
			*/}
			<div className="grid grid-cols-1 items-center">
				<Link to="/" className="btn inline-block mx-auto sm:w-auto">
					zurück zur Startseite
				</Link>
			</div>{" "}
		</div>
	)
}

export default Privacy
