import { useAutoAnimate } from "@formkit/auto-animate/react"
import axios from "axios"
import { useContext, useState } from "react"
import Dropzone from "react-dropzone"
import convertPDF2PNG from "../convertPDF/PDF2PNG"
import Alert from "../elements/Alert"
import Spinner from "../elements/Spinner"
import { getEnvConfig } from "../env"
import { ERRORTYPES } from "../config"
import { Context } from "../store/store"
import { getText } from "../strapi"

interface ReceiptUploadProps {
	dispatch: Function
}
const ReceiptUpload: React.FC<ReceiptUploadProps> = ({ dispatch }) => {
	const env = getEnvConfig()

	const { globalState } = useContext(Context)

	const [loading, setLoading] = useState(false)
	const [error, setError] = useState<ERRORTYPES | null>()
	const [acceptedFile, setAcceptedFile] = useState<any>(null)

	const [receiptUploadRef] = useAutoAnimate<HTMLDivElement>()

	function onDrop(dropedFiles: any) {
		setLoading(true)
		if (dropedFiles.length > 0) {
			if (dropedFiles[0].size > 8000000) {
				// 8MB
				setError(ERRORTYPES.FILESIZE)
				setLoading(false)
				try {
					// eslint-disable-next-line
					let dataLayer = window["dataLayer"] || []
					dataLayer.push({
						event: "form-submit",
						"form-name": "receipt",
						"form-submit": false,
						field_error: "upload_receipt",
					})
				} catch (e) {}
			} else {
				setError(null)

				setLoading(true)
				setAcceptedFile(dropedFiles[0]) // TODO: check if this needs to be holded in the state???
				handleFileType(dropedFiles[0])
			}
		} else {
			setError(ERRORTYPES.FILESIZE)
			setLoading(false)
			try {
				// eslint-disable-next-line
				let dataLayer = window["dataLayer"] || []
				dataLayer.push({
					event: "form-submit",
					"form-name": "receipt",
					"form-submit": false,
					field_error: "upload_receipt",
				})
			} catch (e) {}
		}
	}

	function handleFileTypeError(error: any) {
		try {
			if (error.response.data.errors.errors[0] === "Receipt already used.") {
				setError(ERRORTYPES.DOUBBLED)
			} else if (error.response.data.errors.errors[0] === "Reached maximum attempts with uploaded receipt.") {
				setError(ERRORTYPES.MAXTRIES)
			} else {
				setError(ERRORTYPES.RECEIPT_UPLOAD_ERROR)
			}
		} catch (error) {
			setError(ERRORTYPES.RECEIPT_UPLOAD_ERROR)
		}

		try {
			// eslint-disable-next-line
			let dataLayer = window["dataLayer"] || []
			dataLayer.push({
				event: "form-submit",
				"form-name": "receipt",
				"form-submit": false,
				field_error: "upload_receipt",
			})
		} catch (e) {}

		setLoading(false)
		setAcceptedFile(null)
	}

	function handleFileType(file) {
		if (file.type !== "application/pdf") {
			uploadReceipt(file)
		} else {
			let oldFilePath = file.path
			/* ✨ PDF magic ✨ */
			convertPDF2PNG(file)
				.then(blob => {
					const newFile = new File([blob], oldFilePath + ".png", { type: blob.type })
					uploadReceipt(newFile)
				})
				.catch(error => {
					console.log("Error converting PDF to B64", error)
					setError(ERRORTYPES.RECEIPT_UPLOAD_ERROR)
					console.log("err 3")
					setLoading(false)
					try {
						// eslint-disable-next-line
						let dataLayer = window["dataLayer"] || []
						dataLayer.push({
							event: "form-submit",
							"form-name": "receipt",
							"form-submit": false,
							field_error: "upload_receipt",
						})
					} catch (e) {}
				})
		}
	}

	async function uploadReceipt(file: any) {
		const env = getEnvConfig()
		const formdata = new FormData()
		formdata.append("file", file)

		axios
			.post(env.apibase + "uploadReceipt.php?cv=" + Date.now(), formdata, {
				headers: {
					"content-type": "multipart/form-data",
					Accept: "application/json",
				},
			})
			.then(res => {
				if (res.data.enqueued) {
					checkOCRStatus(res.data.temporaryReceiptDataId, file)

					try {
						// eslint-disable-next-line
						let dataLayer = window["dataLayer"] || []
						dataLayer.push({ event: "receipt", "receipt-upload": "upload-successful" })
					} catch (e) {}
				} else {
					handleFileTypeError(new Error("File submitted but something's wrong 🤔: " + JSON.stringify(res)))

					try {
						// eslint-disable-next-line
						let dataLayer = window["dataLayer"] || []
						dataLayer.push({
							event: "form-submit",
							"form-name": "receipt",
							"form-submit": false,
							field_error: "upload_receipt",
						})
					} catch (e) {}
				}
			})
			.catch(error => {
				handleFileTypeError(error)

				setAcceptedFile(null)
			})
	}

	async function checkOCRStatus(reference: string, file, maxTries: number = 20) {
		axios
			.get(env.apibase + "checkReceiptUploadStatus.php?reference=" + reference + "&cv=" + Date.now())
			.then(res => {
				if (!!res.data.gptPassed) {
					// Process succeeded

					if (res.data.gptProcessed) {
						dispatch({
							type: "SET_RECEIPT_REFERENCE",
							receiptReference: reference,
							receiptFileName: reference,
							receiptFile: file,
						})
						try {
							// eslint-disable-next-line
							let dataLayer = window["dataLayer"] || []
							dataLayer.push({ event: "form-submit", "form-name": "receipt", "form-submit": true })
						} catch (e) {}
					} else {
						setError(ERRORTYPES.RECEIPT_UPLOAD_ERROR)
						console.log("err 4")
						setAcceptedFile(null)
					}

					setLoading(false)
				} else {
					maxTries--
					if (maxTries === 0) {
						setError(ERRORTYPES.RECEIPT_UPLOAD_ERROR)
						console.log("err 5")
						setLoading(false)

						console.log(new Error("Too many attempts!"))
						setAcceptedFile(null)

						try {
							// eslint-disable-next-line
							let dataLayer = window["dataLayer"] || []
							dataLayer.push({
								event: "form-submit",
								"form-name": "receipt",
								"form-submit": false,
								field_error: "upload_receipt",
							})
						} catch (e) {}
					} else {
						setTimeout(() => checkOCRStatus(reference, file, maxTries), 1000)
					}
				}
			})
			.catch(error => {
				console.log(error)
				if (error.response.status === 429) {
					setError(ERRORTYPES.MAXTRIES)
				} else if (error.response.status === 402) {
					setError(ERRORTYPES.PROMOOVER)
				} else {
					setError(ERRORTYPES.RECEIPT_UPLOAD_ERROR)
				}
				console.log("err 6")
				setAcceptedFile(null)
				setLoading(false)
				try {
					// eslint-disable-next-line
					let dataLayer = window["dataLayer"] || []
					dataLayer.push({
						event: "form-submit",
						"form-name": "receipt",
						"form-submit": false,
						field_error: "upload_receipt",
					})
				} catch (e) {}
			})
	}

	return (
		<div
			className="bg-yellow border-[1px] border-dashed border-black/50 rounded-[20px] p-8"
			style={{
				boxShadow: "0px 15px 25px 0px rgba(0, 0, 0, 0.20)",
			}}
		>
			<Dropzone onDrop={onDrop} multiple={false} accept="image/jpeg,image/png,application/pdf">
				{({ getRootProps, getInputProps }) => (
					<section ref={receiptUploadRef}>
						{!loading ? (
							<div {...getRootProps()}>
								<input type="file" accept="image/jpeg,image/png,application/pdf" {...getInputProps()} />

								<div className="">
									<svg
										className="block mx-auto mb-6 "
										width={51}
										height={51}
										viewBox="0 0 51 51"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M25.06 0C11.2326 0 0 11.2326 0 25.06C0 38.8874 11.2326 50.12 25.06 50.12C38.8874 50.12 50.12 38.8874 50.12 25.06C50.12 11.2326 38.8874 0 25.06 0ZM25.06 2.17913C37.7097 2.17913 47.9409 12.4103 47.9409 25.06C47.9409 37.7097 37.7097 47.9409 25.06 47.9409C12.4103 47.9409 2.17913 37.7097 2.17913 25.06C2.17913 12.4103 12.4103 2.17913 25.06 2.17913ZM25.043 9.79119C24.8789 9.79377 24.7174 9.83338 24.5708 9.90708C24.4241 9.98078 24.296 10.0867 24.196 10.2168C24.1946 10.2189 24.1932 10.2211 24.1918 10.2232L13.394 21.0209C13.2894 21.1213 13.2059 21.2416 13.1484 21.3747C13.0909 21.5077 13.0605 21.6509 13.059 21.7959C13.0576 21.9409 13.085 22.0847 13.1398 22.2189C13.1946 22.3531 13.2756 22.475 13.3781 22.5775C13.4807 22.68 13.6026 22.761 13.7368 22.8158C13.871 22.8706 14.0148 22.8981 14.1598 22.8966C14.3047 22.8951 14.4479 22.8647 14.581 22.8072C14.7141 22.7497 14.8343 22.6662 14.9347 22.5617L23.9704 13.5259V39.2243C23.9684 39.3687 23.9951 39.5121 24.0489 39.6461C24.1027 39.78 24.1827 39.902 24.2841 40.0048C24.3854 40.1076 24.5062 40.1893 24.6394 40.245C24.7727 40.3007 24.9156 40.3294 25.06 40.3294C25.2044 40.3294 25.3474 40.3007 25.4806 40.245C25.6138 40.1893 25.7346 40.1076 25.8359 40.0048C25.9373 39.902 26.0173 39.78 26.0711 39.6461C26.1249 39.5121 26.1516 39.3687 26.1496 39.2243V13.5259L35.1853 22.5617C35.2857 22.6662 35.4059 22.7497 35.539 22.8072C35.6721 22.8647 35.8153 22.8951 35.9602 22.8966C36.1052 22.8981 36.249 22.8706 36.3832 22.8158C36.5174 22.761 36.6393 22.68 36.7419 22.5775C36.8444 22.475 36.9254 22.3531 36.9802 22.2189C37.035 22.0847 37.0624 21.9409 37.061 21.7959C37.0595 21.6509 37.0291 21.5077 36.9716 21.3747C36.9141 21.2416 36.8306 21.1213 36.726 21.0209L25.9219 10.2168C25.8186 10.0824 25.6853 9.97392 25.5328 9.90003C25.3802 9.82615 25.2125 9.78888 25.043 9.79119Z"
											fill="#235FAF"
										/>
									</svg>
									{acceptedFile ? (
										<>
											{/* <img
												src={process.env.PUBLIC_URL + "/images/icon-uploaded.svg"}
												width="74"
												height="71"
												alt=""
											/> */}
											<p className="text-black text-[16px] max-w-[250px] mx-auto text-ellipsis  whitespace-nowrap overflow-hidden pb-4">
												{acceptedFile.name}
											</p>
										</>
									) : (
										<>
											<p className="font-Noto text-[18px] font-black">
												{getText(globalState.strapi.homepage.uploadNow, "uploadNow")}
											</p>
											<p className="pb-6 ">
												{getText(globalState.strapi.homepage.uploadInfo, "uploadInfo")}
											</p>
											<span className="btn btn-small">
												{getText(globalState.strapi.homepage.uploadSubmit, "uploadSubmit")}
											</span>
										</>
									)}
								</div>
							</div>
						) : (
							<div className="flex items-center justify-center text-center min-h-[160px]">
								<Spinner />
							</div>
						)}
						{error && <Alert className={"text-left mt-3"}>{error}</Alert>}
					</section>
				)}
			</Dropzone>
		</div>
	)
}

export default ReceiptUpload
