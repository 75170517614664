import { useContext, useRef } from "react"
import Slider from "react-slick"
import { Context } from "../store/store"
import { gatherChildren, getText } from "../strapi"
import { Link } from "react-router-dom"

function Sogehts({ page = "" }) {
	const { globalState } = useContext(Context)
	const sogehtsSliderRef = useRef<any>()
	const config = {
		dots: false,
		infinite: false,
		speed: 300,
		slidesToShow: 3,
		arrows: true,
		responsive: [
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1,
				},
			},
		],
	}

	return (
		<>
			<div className="relative z-0 bg-[#d7f4fc]  pt-[11%] -mt-[1rem] md:mt-0">
				<img
					src={process.env.PUBLIC_URL + "/images/tropfen.png"}
					alt={"not selected"}
					loading="lazy"
					className="absolute left-0 top-0 w-[40%] z-0"
				/>

				<div className="relative z-10 ">
					<section className="pb-11 relative z-0">
						<div className="container mx-auto px-4 max-w-[1200px] ">
							<h2 className="pb-6 mt-12 md:mt-0">
								{getText(globalState.strapi.homepage.soGehtsHeadline, "soGehtsHeadline")}
							</h2>

							<Slider {...config} ref={sogehtsSliderRef} className="text-center -mx-4 blue">
								<div>
									<img
										src={process.env.PUBLIC_URL + "/images/step1.png"}
										className="w-[40%] md:w-[60%] mx-auto block"
										alt=""
									/>
									<div className="pt-4 leading-tight font-Chronic text-[32px] text-red mt-6 md:mt-0 max-w-[70%] md:max-w-[70%] mx-auto">
										{getText(globalState.strapi.homepage.soGehtsStep1, "soGehtsStep1")}
									</div>
								</div>
								<div>
									<img
										src={process.env.PUBLIC_URL + "/images/step2.png"}
										className="w-[40%] md:w-[60%]  mx-auto block"
										alt=""
									/>
									<div className="pt-4 leading-tight font-Chronic text-[32px] text-red mt-6 md:mt-0 max-w-[70%] md:max-w-[70%]  mx-auto">
										{getText(globalState.strapi.homepage.soGehtsStep2, "soGehtsStep2")}
									</div>
								</div>
								<div>
									<img
										src={process.env.PUBLIC_URL + "/images/step3.png"}
										className="w-[40%] md:w-[60%]  mx-auto block"
										alt=""
									/>
									<div className="pt-4 leading-tight font-Chronic text-[32px] text-red mt-6 md:mt-0 max-w-[70%] md:max-w-[70%]  mx-auto">
										{getText(globalState.strapi.homepage.soGehtsStep3, "soGehtsStep3")}
									</div>
								</div>
							</Slider>
						</div>
						<div className="text-center pt-7 text-[14px] md:text-[18px] max-w-[700px] mx-auto px-4 md:px-0">
							<div className="pt-2">
								{gatherChildren(globalState.strapi.homepage.soGehtsTeilnahmeSchluss)}
							</div>
							<p className="px-4 md:px-0">
								Weitere Informationen in unseren{" "}
								<Link className="underline text-red" to={"/teilnahmebedingungen"}>
									Teilnahmebedingungen
								</Link>
								.
							</p>
						</div>
					</section>
				</div>
			</div>
			{page === "start" ? (
				<img
					src={process.env.PUBLIC_URL + "/images/bottomLine.png"}
					alt="asd"
					className="w-full relative z-20 -scale-x-100 -scale-y-100  -top-[1px]"
					style={{
						filter: "brightness(0) saturate(100%) invert(95%) sepia(3%) saturate(1516%) hue-rotate(151deg) brightness(99%) contrast(99%)",
					}}
				/>
			) : (
				<>
					<div className="grid grid-cols-1 items-center relative top-12 md:top-20 z-[30]">
						<a
							rel="noreferrer"
							href="https://www.kinder.com/"
							className="btn inline-block mx-auto sm:w-auto"
							target="_blank"
						>
							zu kinder
						</a>
					</div>{" "}
					<img
						src={process.env.PUBLIC_URL + "/images/bottomLine.png"}
						alt="asd"
						className="w-full relative z-20 -scale-x-100 -scale-y-100 ] rotate-180"
					/>
				</>
			)}
		</>
	)
}

export default Sogehts
