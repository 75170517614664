import { useContext, useEffect } from "react"
import TextBook from "../components/TextBook"
import { TextBookKeys } from "../config"
import Spinner from "../elements/Spinner"
import { Context } from "../store/store"
import { gatherChildren, getText } from "../strapi"

function Promoover() {
	const { globalState } = useContext(Context)
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}, [])

	return (
		<>
			<div className="relative z-10  mt-0 md:-mt-6">
				<picture>
					<source media="(min-width: 640px)" srcSet={process.env.PUBLIC_URL + "/images/hero.png"} />
					<img
						src={process.env.PUBLIC_URL + "/images/header-mobile.png"}
						className="w-full relative z-0 block mb:hidden mt-12 md:mt-0"
						alt=""
					/>
				</picture>
			</div>
			<section className="bg-center bg-cover bg-no-repeat pt-24 relative bg-[#d7f4fc]  md:pt-48 -mt-[16%] md:-mt-[11%]">
				<img
					src={process.env.PUBLIC_URL + "/images/promoover-badge.png"}
					alt="alt"
					width={150}
					height={150}
					className="w-[150px] md:w-[350px] absolute -top-12 md:-top-[20%] md:left-[28%] z-[80]"
				/>

				<>
					<img
						src={process.env.PUBLIC_URL + "/images/tropfen.png"}
						alt={"not selected"}
						loading="lazy"
						className="absolute left-0 top-0 w-[40%] z-0"
					/>
					{globalState.strapi.homepage ? (
						<>
							<div className="container max-w-[840px] mx-auto px-4  ">
								<div className="mb-6 md:mb-12 ">
									<h2>
										{" "}
										{getText(globalState.strapi.homepage.promoOverHeadline, "promoOverHeadline")}
									</h2>
								</div>
								<div className="grid grid-cols-1 gap-6 md:pb-12">
									<p className="text-blue text-center text-[15px] md:text-[22px] order-1 sm:order-2 md:leading-10">
										{gatherChildren(globalState.strapi.homepage.promoOverCopy1)}
									</p>
								</div>
							</div>
							<>
								<div className="grid grid-cols-1 items-center relative top-12 md:top-20 z-[30]">
									<a
										href="https://www.kinder.com/"
										rel="noreferrer"
										className="btn inline-block mx-auto sm:w-auto"
										target="_blank"
									>
										zu kinder
									</a>
								</div>{" "}
								<img
									src={process.env.PUBLIC_URL + "/images/bottomLine.png"}
									alt="asd"
									className="w-full relative z-20 -scale-x-100 -scale-y-100 ] rotate-180"
								/>
							</>
						</>
					) : (
						<div className="text-center py-20">
							<TextBook textKey={TextBookKeys.HOMEPAGE} />
							<Spinner />
						</div>
					)}
				</>
			</section>
		</>
	)
}
export default Promoover
