function checkURLParameter(parameterName: string): boolean {
	// Hole die URL aus dem Browser
	const urlParams = new URLSearchParams(window.location.search)

	// Überprüfe, ob der Parameter in der URL vorhanden ist
	return urlParams.has(parameterName)
}

export function getText(text: string, key: string) {
	// Beispielaufruf
	const parameterExists = checkURLParameter("showTextKeys")
	if (parameterExists) {
		return "[" + key + "]"
	}
	return text || "[" + key + "]"
}

function repaceEmailsByLinks(text: string) {
	const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g
	const imgPackungRegex = /\[IMGPACKUNG\]/g

	if (text === "") {
		// fix for rich text editor spacing
		text = "&nbsp;"
	}

	return text
		.replaceAll(emailRegex, email => {
			return `<a class="underline font-bold" href="mailto:${email}">${email}</a>`
		})
		.replaceAll(imgPackungRegex, image => {
			return `<img src="${process.env.PUBLIC_URL}/images/pack.png" alt="" />`
		})
}

export function gatherCopy(childs) {
	return childs.map(child => {
		if (child.type === "paragraph") {
			return <div>{repaceEmailsByLinks(child.text)}</div>
		} else if (child.type === "link") {
			return (
				<a target="_blank" href={child.url} className="underline font-bold" rel="noreferrer">
					{gatherCopy(child.children)}
				</a>
			)
		} else if (child.type === "link") {
			switch (child.format) {
				case "ordered":
					return <ol>{gatherCopy(child.children)}</ol>
				case "unordered":
					return <ul>{gatherCopy(child.children)}</ul>
			}
		} else if (child.type === "list-item") {
			return <li>{gatherCopy(child.children)}</li>
		} else if (child.type === "quote") {
			return <blockquote>{gatherCopy(child.children)}</blockquote>
		} else if (child.type === "code") {
			return <pre>{gatherCopy(child.children)}</pre>
		} else if (child.type === "image") {
			return <img src="{child.image.url}" height="{child.image.height}" width="{child.image.width}" alt="" />
		} else if (child.type === "text") {
			return (
				<span
					key={"span" + JSON.stringify(child.type).length}
					dangerouslySetInnerHTML={{ __html: repaceEmailsByLinks(child.text) }}
				/>
			)
		}

		return JSON.stringify(child)
	})
}
export function gatherChildren(childs) {
	return childs.map(child => (
		<p key={"p" + JSON.stringify(child.children).length} className="pb-6">
			{gatherCopy(child.children)}
		</p>
	))
}
