import React, { useContext, useEffect } from "react"
import TagManager from "react-gtm-module"
import TextBook from "../components/TextBook"
import { TextBookKeys } from "../config"
import Spinner from "../elements/Spinner"
import { Context } from "../store/store"
import { Link } from "react-router-dom"
import StrapiContentPages from "../components/StrapiContentPages"

const Terms: React.FC = () => {
	const { globalState } = useContext(Context)
	console.log(globalState.strapi)
	// GTM

	const tagManagerArgs = {
		dataLayer: {
			page: "erweiterterdatenschutz",
		},
		dataLayerName: "dataLayer",
	}
	TagManager.dataLayer(tagManagerArgs)

	// SEO Meta Tags

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
		})
	}, [])

	return (
		<>
			<div className="relative z-10  mt-0 md:-mt-6">
				<picture>
					<source media="(min-width: 640px)" srcSet={process.env.PUBLIC_URL + "/images/hero.png"} />
					<img
						src={process.env.PUBLIC_URL + "/images/header-mobile.png"}
						className="w-full relative z-0 block mb:hidden mt-12 md:mt-0"
						alt=""
					/>
				</picture>
			</div>
			<h1 className="h1 hyphens-auto text-center sm:hyphens-none text-blue text-[42px] md:text-[54px] pt-6 md:pt-0">
				Teilnahmebedingungen
			</h1>
			{globalState.strapi.term ? (
				<StrapiContentPages textBook={globalState.strapi.term} />
			) : (
				<div className="text-center py-20">
					<Spinner />
					<TextBook textKey={TextBookKeys.TERMS} />
				</div>
			)}
			<div className="grid grid-cols-1 items-center">
				<Link to="/" className="btn inline-block mx-auto sm:w-auto">
					zurück zur Startseite
				</Link>
			</div>{" "}
		</>
	)
}
export default Terms
