import * as pdfjsLib from "pdfjs-dist"
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry"
import b64toBlob from "./b64toBlob"

pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker

async function pdf2b64(data) {
	return new Promise((resolve, reject) => {
		var loadingTask = pdfjsLib.getDocument(data)
		loadingTask.promise.then(
			function (pdf) {
				// console.log("PDF loaded")

				// Fetch the first page
				var pageNumber = 1
				pdf.getPage(pageNumber).then(function (page) {
					// console.log("Page loaded")

					var scale = 1.5
					var viewport = page.getViewport({ scale: scale })

					// Prepare canvas using PDF page dimensions
					const canvas = document.createElement("canvas")
					let context = canvas.getContext("2d")
					canvas.height = viewport.height
					canvas.width = viewport.width

					// Render PDF page into canvas context
					var renderContext = {
						canvasContext: context,
						viewport: viewport,
					}
					var renderTask = page.render(renderContext)
					renderTask.promise.then(function () {
						// console.log("Page rendered")
						let data = canvas.toDataURL("image/jpeg")
						resolve(data)
					})
				})
			},
			function (reason) {
				// PDF loading error
				reject(reason)
			}
		)
	})
}

async function convertPDF2PNG(file) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.readAsDataURL(file)

		reader.onload = () => {
			pdf2b64(reader.result)
				.then(b64 => {
					b64toBlob(b64)
						.then(img => resolve(img))
						.catch(error => reject(error))
				})
				.catch(error => {
					reject(error)
				})
		}
	})
}
export default convertPDF2PNG
