import { gatherCopy, getText } from "../strapi"

function StrapiContentPages({ textBook }) {
	return (
		<section className="bg-white   pageContent">
			<div className="container max-w-[1200px] mx-auto px-8 ">
				<h1 className="h1 text-center text-blue text-[42px] md:text-[54px] pt-6 md:pt-0">
					{getText(textBook.headline, "headline")}
				</h1>
				{textBook.content.map((block, index) => {
					if (block.type === "paragraph") {
						return <p key={"terms" + index}>{gatherCopy(block.children)}</p>
					} else if (block.type === "heading") {
						if (block.level === 3) {
							return (
								<h2 className="h2 mb-12" key={"terms" + index}>
									{gatherCopy(block.children)}
								</h2>
							)
						} else {
							return (
								<h3 className="h3 mt-6 mb-6" key={"terms" + index}>
									{gatherCopy(block.children)}
								</h3>
							)
						}
					} else {
						return <div key={"terms" + index}>{block.text}</div>
					}
				})}
			</div>
		</section>
	)
}

export default StrapiContentPages
