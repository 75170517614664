import React, { useContext } from "react"
import TagManager from "react-gtm-module"
import TextBook from "../components/TextBook"
import { TextBookKeys } from "../config"
import Spinner from "../elements/Spinner"
import CashbackForm from "../Form/CashbackForm"
import { Context } from "../store/store"
import PromoOverHandler from "../components/PromoOverHandler"
import Sharing from "../components/Sharing"

declare global {
	interface Window {
		CookieBot: any
	}
}

const Home: React.FC = () => {
	// GTM
	const tagManagerArgs = {
		dataLayer: {
			page: "startseite",
		},
		dataLayerName: "dataLayer",
	}
	TagManager.dataLayer(tagManagerArgs)
	// SEO Meta Tags

	const { globalState } = useContext(Context)

	return (
		<PromoOverHandler>
			{globalState.strapi.homepage ? (
				<>
					<CashbackForm />
					<Sharing />
				</>
			) : (
				<div className="text-center py-20">
					<Spinner />
					<TextBook textKey={TextBookKeys.HOMEPAGE} />
				</div>
			)}
		</PromoOverHandler>
	)
}
export default Home
