import { useState, useEffect } from "react"
import axios from "axios"
import Spinner from "../elements/Spinner"
import { getEnvConfig } from "../env"
import ElemCheckbox from "../elements/Checkbox"

const env = getEnvConfig()

const CepCheckboxes = ({ register, errors, resetCepCheckboxes }) => {
	const [detailsVisible, setDetailsVisible] = useState(false)

	const [cepStatusText, setCepStatusText] = useState<any>({
		allMarketingConsent: null,
		emailMarketingConsent: null,
		dataMatchingConsent: null,
		personalAdConsent: null,
		postalDeliveryConsent: null,
	})

	/**
	 *
	 * @param text
	 * @returns
	 */
	const replaceDatamatching = text => {
		const regex = /\[\[partnerCompanies([^[\]]*)\]\[([^[\]]*)\]\]/g
		let textArray = text.split(regex)
		return [
			textArray[0],
			<a href="partner" key="partnerlink2" target="_blank" style={{ textDecoration: "underline" }}>
				{textArray[2]}
			</a>,
			textArray[3],
		]
	}

	/**
	 *
	 * @param text
	 * @returns
	 */
	const replaceAllMarketingConsentText = text => {
		const regexDataMatch = /\[\[emailMarketingConsent([^[\]]*)\]\[([^[\]]*)\]\]/g
		let textArray = text.split(regexDataMatch)

		const regexPartnerlink = /\[\[partnerCompanies([^[\]]*)\]\[([^[\]]*)\]\]/g
		let partnerArray = textArray[0].split(regexPartnerlink)

		return [
			partnerArray[0],
			<a
				href="partner"
				key="partnerlink"
				target="_blank"
				className="link "
				style={{ textDecoration: "underline" }}
			>
				{partnerArray[2]}
			</a>,
			partnerArray[3],
			<span key="ceplink" onClick={showDetails} className="link " style={{ textDecoration: "underline" }}>
				{textArray[2]}
			</span>,
			textArray[3],
		]
	}

	/**
	 *
	 * @param e
	 */
	const showDetails = e => {
		e.stopPropagation()
		e.preventDefault()
		setDetailsVisible(true)
		scrollToCEP()
		resetCepCheckboxes()
	}

	const scrollToCEP = () => {
		setTimeout(() => {
			let errField = document.getElementsByClassName("js-cep")
			if (errField.length > 0) {
				const firstErrorField: any = errField[0]
				window.scrollTo({
					top: window.pageYOffset + firstErrorField.getBoundingClientRect().top - 200,
					left: 0,
					behavior: "smooth",
					// top: firstErrorField.offsetTop, left: 0, behavior: 'smooth'
				})
			}
		}, 150)
	}

	useEffect(() => {
		axios
			.get(env.apibase + "marketingtext.php?cv=" + Date.now())
			.then(res => {
				setCepStatusText({
					text_allMarketingConsent: replaceAllMarketingConsentText(res.data.data.allMarketingConsent),
					text_emailMarketingConsent: res.data.data.emailMarketingConsent,
					text_dataMatchingConsent: replaceDatamatching(res.data.data.dataMatchingConsent),
					text_personalAdConsent: res.data.data.personalAdConsent,
					text_postalDeliveryConsent: res.data.data.postalDeliveryConsent,
				})
			})
			.catch(error => {
				console.log(error)
			})
			.then(() => {})

		// eslint-disable-next-line
	}, [])

	const disclaimer = (
		<p className="p-link text-black pt-6  pb-4 pl-[2.2rem] md:pl-8 text-[15px] md:text-[17px]">
			Ich kann meine Einwilligungen jederzeit widerrufen, z.B. mit einer E-Mail an{" "}
			<a href="mailto:consumerservice@ferrero.com" style={{ textDecoration: "underline" }}>
				consumerservice@ferrero.com
			</a>
			. Die Datenschutzerklärung finde ich{" "}
			<a
				href="https://www.ferrero.de/?blank=legal"
				target="_blank"
				style={{ textDecoration: "underline" }}
				rel="noreferrer"
			>
				hier
			</a>
			.
		</p>
	)

	if (detailsVisible) {
		return (
			<div className="text-white js-cep">
				{cepStatusText.text_emailMarketingConsent && (
					<ElemCheckbox
						hasError={errors.emailMarketingConsent}
						errMsg={
							"Damit Du die Angebote und Informationen erhältst, ist das Aktivieren zumindest der ersten Checkbox erforderlich."
						}
						label={cepStatusText.text_emailMarketingConsent}
					>
						<input
							{...register("emailMarketingConsent")}
							type="checkbox"
							className="w-1 h-1 hidden absolute peer"
						/>
					</ElemCheckbox>
				)}
				{cepStatusText.text_postalDeliveryConsent && (
					<ElemCheckbox
						hasError={errors.postalDeliveryConsent}
						errMsg={"Terms bestätigen"}
						label={cepStatusText.text_postalDeliveryConsent}
					>
						<input
							{...register("postalDeliveryConsent")}
							type="checkbox"
							className="w-1 h-1 hidden absolute peer"
						/>
					</ElemCheckbox>
				)}
				{cepStatusText.text_personalAdConsent && (
					<ElemCheckbox
						hasError={errors.personalAdConsent}
						errMsg={"Terms bestätigen"}
						label={cepStatusText.text_personalAdConsent}
					>
						<input
							{...register("personalAdConsent")}
							type="checkbox"
							className="w-1 h-1 hidden absolute peer"
						/>
					</ElemCheckbox>
				)}
				{cepStatusText.text_dataMatchingConsent && (
					<ElemCheckbox
						hasError={errors.dataMatchingConsent}
						errMsg={"Terms bestätigen"}
						label={cepStatusText.text_dataMatchingConsent}
					>
						<input
							{...register("dataMatchingConsent")}
							type="checkbox"
							className="w-1 h-1 hidden absolute peer"
						/>
					</ElemCheckbox>
				)}
				{disclaimer}
			</div>
		)
	} else {
		return (
			<>
				{cepStatusText.text_allMarketingConsent ? (
					<div className="text-white ">
						<ElemCheckbox
							hasError={errors.allMarketingConsent}
							errMsg={"Terms bestätigen"}
							label={cepStatusText.text_allMarketingConsent}
						>
							<input
								{...register("allMarketingConsent")}
								type="checkbox"
								className="w-1 h-1 hidden absolute peer"
							/>
						</ElemCheckbox>

						{disclaimer}
					</div>
				) : (
					<Spinner />
				)}
			</>
		)
	}
}

export default CepCheckboxes
