export enum ERRORTYPES {
	PROMOOVER = "Die Aktion ist leider schon beendet",
	RECEIPT_UPLOAD_ERROR = "Leider konnte die Datei nicht hochgeladen werden. Das Dateiformat muss überprüft werden",
	FILESIZE = "Dein ausgewählter Kassenbon entspricht nicht den Vorgaben. JPG, PNG oder PDF / max. 8 MB",
	DOUBBLED = "Du hast Deinen Kassenbon bereits verwendet.",
	MAXTRIES = "Du hast heute bereits zu häufig an unserem Gewinnspiel teilgenommen. Bitte probiere es morgen noch einmal.",
	SERVER = "Bei der Übertragung Deiner Daten ist ein Fehler aufgetreten. Bitte versuche es erneut. Sollten die Probleme weiterhin bestehen, kannst Du Dich an unser Service-Team wenden. Unter der E-Mail info@kinder-paradiso-gratistesten.de sind wir erreichbar. Bitte entschuldige die Unannehmlichkeiten.",
	UPLOADSMISSINGRECEIPT = "Bitte den Kassenbon hochladen.",
	PARSE_ORDER_ERRORS = "PARSE_ORDER_ERRORS",
}

export const formRegEx = {
	// eslint-disable-next-line
	alpha: new RegExp(
		"^[A-Za-zäöüßãâåàáäæçéèêëìíîïłñôõóòøúûÿıÄÖÜÃÂÅÀÁÄÆÇÉÈÊËÌÍÎÏŁÑÔÕÓÒØÚÛŸI0-9]+[A-Za-zäöüßãâåàáäæçéèêëìíîïłñôõóòøúûÿıÄÖÜÃÂÅÀÁÄÆÇÉÈÊËÌÍÎÏŁÑÔÕÓÒØÚÛŸI0-9s. -]*.?"
	),
	// eslint-disable-next-lin
	email: new RegExp("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$"),
	numerical: new RegExp("^[0-9]*$"),
	// eslint-disable-next-line
	birthdate: new RegExp("^([0-2][0-9]|(3)[0-1])(.)(((0)[0-9])|((1)[0-2]))(.)d{4}$"),
}

export const MAX_PRODUCT_PRICE = 3

export enum ErrorMessage {
	NONE,
	EMPTY,
	UNKNOWN,
	ALLOWED,
	BLOCKED,
	NOTALLOWEDCHAR,
}
export enum TextBookKeys {
	HOMEPAGE = "homepage",
	FAQ = "faq",
	TERMS = "term",
	CONTACT = "contact",
	EXTENDED_PRIVACY = "extendeprivacy",
}

export enum BLACKLIST {
	NONE = "NONE",
	UNKNOWN = "UNKNOWN",
	BLOCKED = "BLOCKED",
	ALLOWED = "ALLOWED",
}

export function gtmEvt(evt) {
	try {
		// eslint-disable-next-line
		let dataLayer = window["dataLayer"] || []
		dataLayer.push({
			event: evt,
		})
	} catch (e) {}
}

export const RECAPTCHAKEY = "6Lcwxx8qAAAAAMk6Zz9QTup3mT8AEHCVdx0e152C"

export const AZReferer = "restful.ferrero.az"
export const AZUsername = "ferr81g5-arva-5572-t3cv-75h5ap061k2u"
export const AZPassword = "j739pde2-k64k-7939-oo80-38je2gh5pt3a"
