import { useContext, useEffect } from "react"
import { BLACKLIST, RECAPTCHAKEY } from "../config"
import Alert from "../elements/Alert"
import Receipt from "../receipt/Receipt"
import { Context } from "../store/store"
import { getText } from "../strapi"
import CepCheckboxes from "./CepCheckboxes"
import {
	FieldAccountBic,
	FieldAccountHolder,
	FieldAccountIban,
	FieldBirthday,
	FieldEmail,
	FieldFirstname,
	FieldKaufbetrag,
	FieldKaufdatum,
	FieldKaufstätte,
	FieldLastname,
	FieldSalutation,
	FieldTerms,
} from "./FerreroFormFields"
import PrefillForm from "./PrefillForm"
import AutoAnimate from "../components/AutoAnimate"
import Spinner from "../elements/Spinner"
import FormRecaptchaBtn from "./FormRecaptcha"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import MafoQuestions from "./MafoQuestions"

function FerreroForm({
	handleSubmit,
	handleFormSubmit,
	handleFormSubmitError,
	register,
	errors,
	watch,
	setValue,
	isLoading,
	setError,
	clearErrors,
}) {
	const { globalState } = useContext(Context)
	return (
		<div>
			<h2 className="text-red mt-6 md:mt-0">
				{getText(globalState.strapi.homepage.formHeadline, "formHeadline")}
			</h2>
			<PrefillForm setValue={setValue} />
			<GoogleReCaptchaProvider language="de" useRecaptchaNet={true} reCaptchaKey={RECAPTCHAKEY}>
				<form onSubmit={handleSubmit(handleFormSubmit, handleFormSubmitError)} autoComplete="off" noValidate>
					<AutoAnimate className="mb-3 grid grid-cols-1 gap-6">
						<Receipt />
						{errors && errors.recieptId && <Alert>{errors.recieptId}</Alert>}

						<h3 className="md:mt-8">
							{getText(globalState.strapi.homepage.formHlKassenbon, "formHlKassenbon")}
						</h3>
						<p className="text-black font-Noto font-bold text-[17px] text-center">
							{getText(globalState.strapi.homepage.formCopyKassenbon, "formCopyKassenbon")}
						</p>

						<div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
							<FieldKaufdatum register={register} errors={errors} watch={watch} />
							<FieldKaufbetrag register={register} errors={errors} watch={watch} />
							<FieldKaufstätte register={register} errors={errors} watch={watch} />
						</div>

						<h3 className="md:mt-8">{getText(globalState.strapi.homepage.formHlPerson, "formHlPerson")}</h3>

						<div className="grid  grid-cols-1 sm:grid-cols-3 gap-4">
							<FieldSalutation register={register} errors={errors} watch={watch} />
						</div>

						<div className="grid  grid-cols-1 sm:grid-cols-2 gap-4">
							<FieldFirstname errors={errors} register={register} watch={watch} />
							<FieldLastname errors={errors} register={register} watch={watch} />
						</div>

						<FieldEmail errors={errors} register={register} watch={watch} isRequired={true} />
						<h3 className="md:mt-8">
							{getText(globalState.strapi.homepage.formHlAccount, "formHlAccount")}
						</h3>

						<div className="grid  grid-cols-1 sm:grid-cols-2 gap-4">
							<FieldAccountHolder errors={errors} register={register} watch={watch} />
							<FieldAccountIban errors={errors} register={register} watch={watch} />
						</div>
						{watch("iban") &&
							watch("iban").length > 2 &&
							watch("iban").substring(0, 2).toUpperCase() !== "DE" && (
								<FieldAccountBic errors={errors} register={register} watch={watch} />
							)}

						<div className="px-4 md:px-9">
							<FieldTerms errors={errors} register={register} />
							<div className="text-left text-[15px] text-black">* Pflichtfelder</div>
							<div className="bg-white/50 p-link py-4 md:p-9  rounded-[20px] md:mt-6">
								<h3 className="pb-4 md:pb-0">Lust auf mehr von Ferrero?</h3>
								<div className="px-4 md:px-9">
									<CepCheckboxes
										register={register}
										errors={errors}
										resetCepCheckboxes={() => {
											setValue("allMarketingConsent", false)
											setValue("dataMatchingConsent", false)
											setValue("personalAdConsent", false)
											setValue("postalDeliveryConsent", false)
											setValue("emailMarketingConsent", false)
										}}
									/>
								</div>
							</div>

							{(watch("allMarketingConsent") ||
								watch("dataMatchingConsent") ||
								watch("personalAdConsent") ||
								watch("postalDeliveryConsent") ||
								watch("emailMarketingConsent")) && (
								<div className="py-6">
									<FieldBirthday errors={errors} register={register} watch={watch} />
									{process.env.NODE_ENV === "development" && (
										<div className="btn" onClick={() => setValue("dateofbirth", "1988-12-15")}>
											set birthday
										</div>
									)}
								</div>
							)}
							<MafoQuestions register={register} errors={errors} watch={watch} />
							{isLoading && (
								<div className="text-center">
									<Spinner />
								</div>
							)}
						</div>
					</AutoAnimate>

					<div className="text-center my-8 mb-24">
						<FormRecaptchaBtn
							isLoading={isLoading ? true : undefined}
							label={getText(globalState.strapi.homepage.submit, "submit")}
						/>
					</div>
				</form>
			</GoogleReCaptchaProvider>
		</div>
	)
}

export default FerreroForm
