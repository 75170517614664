"use client"
import { useCallback, useEffect } from "react"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import Spinner from "../elements/Spinner"

const FormRecaptchaBtn = ({ isLoading, label }) => {
	const { executeRecaptcha } = useGoogleReCaptcha()

	// Create an event handler so you can call the verification on button click event or form submit
	const handleReCaptchaVerify = useCallback(async () => {
		if (!executeRecaptcha) {
			console.log("Execute recaptcha not yet available")
		}
		try {
			// eslint-disable-next-line
			const token = await executeRecaptcha!("yourAction")
		} catch (e) {}
		// Do whatever you want with the token
		// eslint-disable-next-line
	}, [executeRecaptcha])

	// You can use useEffect to trigger the verification as soon as the component being loaded
	useEffect(() => {
		handleReCaptchaVerify()
	}, [handleReCaptchaVerify])

	function clickhandler(e) {
		handleReCaptchaVerify()
	}
	return (
		<button type="submit" onClick={e => clickhandler(e)} className="btn " disabled={isLoading}>
			{label}
		</button>
	)
}

export default FormRecaptchaBtn
