import React from "react"
import Alert from "./Alert"

interface CheckboxProps {
	props?: any
	className?: string | null
	label?: any
	errMsg?: string
	hasError?: any
	children: any
}

const ElemCheckbox: React.FC<CheckboxProps> = ({ className, label, errMsg, hasError, children }) => {
	return (
		<label className={"relative block cursor-pointer " + (className || "")}>
			{children}
			{hasError ? (
				<img
					src={process.env.PUBLIC_URL + "/images/icon-checkbox-error.svg"}
					alt={"not selected"}
					loading="lazy"
					className="peer-checked:hidden block absolute w-[20px] mr-[20px]"
				/>
			) : (
				<img
					src={process.env.PUBLIC_URL + "/images/icon-checkbox.svg"}
					alt={"not selected"}
					loading="lazy"
					className="peer-checked:hidden block absolute w-[20px] mr-[20px]"
				/>
			)}
			<img
				src={process.env.PUBLIC_URL + "/images/icon-checkbox-checked-new.svg"}
				alt={"selected"}
				loading="lazy"
				className="peer-checked:block hidden absolute w-[20px] mr-[20px]"
			/>
			<div className="pl-9 text-black font-Noto text-[15px] md:text-[17px] leading-normal">{label}</div>
			{hasError && <div className="formErrorMsg js-formerror mb-4 text-[18px]  ml-9">{errMsg}</div>}
		</label>
	)
}

export default ElemCheckbox
