interface DialogProps {
	children?: any
}

const ElemDialog: React.FC<DialogProps> = ({ children }) => {
	return (
		<div className="animate-showDialog fixed w-[100vw] h-[100vh] top-0 left-0 z-10 opacity-100 bg-black/20">
			<div
				className="overflow-y-auto animate-showDialogInner px-[28px] sm:px-[50px] py-[28px] sm:py-[40px]  bg-white rounded-md  absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] max-w-[500px] w-[80%]"
				style={{ boxShadow: "0px 20px 25px 0px rgba(0, 0, 0, 0.15)" }}
			>
				{children}
			</div>
		</div>
	)
}

export default ElemDialog
