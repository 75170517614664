import React, { createContext, useReducer } from "react"
import Reducer from "./reducer"

const initialState = {
	strapi: {
		homepage: null,
		faq: null,
	},

	showThankyouPage: false,
}

const Store = ({ children }) => {
	const [globalState, dispatch] = useReducer(Reducer, initialState)
	return <Context.Provider value={{ globalState, dispatch }}>{children}</Context.Provider>
}
export const Context = createContext<{
	globalState
	dispatch: React.Dispatch<any>
}>({
	globalState: initialState,
	dispatch: () => {},
})
export default Store
